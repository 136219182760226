@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/*日本語用（）*/
/*英語用*/
@media screen and (max-width: 650px) {
  /*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  article, aside, footer, nav, section, figcaption, figure, main {
    display: block; }
  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  sub {
    bottom: -.25em; }
  sup {
    top: -.5em; }
  img {
    border-style: none;
    vertical-align: top; }
  table {
    border-collapse: collapse; }
  button, input, select, textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button; }
  textarea {
    overflow: auto;
    resize: vertical; }
  [type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  a, area, button, input, label, select, summary, textarea, [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation; }
  /* --------------- variables --------------------- */
  /* Break Point */
  /* Color */
  /*サイトカラー*/
  /*テキストに使うフォントカラー*/
  /*テキスト内で使うリンクカラー*/
  /*日本語用（）*/
  /*英語用*/
  #screen {
    width: 100%;
    position: relative;
    height: 100vh; }
    #screen .topImg {
      width: 100%;
      height: 100vh; }
      #screen .topImg img {
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto; }
        #screen .topImg img.bg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translateX(-50%) translateY(-50%);
          transition: all 20s; }
          #screen .topImg img.bg.CurrentImg {
            max-width: none;
            max-height: none;
            transform: translateX(-50%) translateY(-50%) scale(1.5); }
  #wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1; }
  /* ----------------------- SVG ---------------------- */
  #mainV {
    position: relative;
    width: 100%;
    height: 100%; }
    #mainV svg {
      transform: rotate(-90deg); }
  .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .circle {
    fill: transparent;
    stroke: #71bd29;
    stroke-width: 8;
    stroke-linecap: round; }
  .circle2 {
    fill: transparent;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 0 6;
    stroke-linecap: round; }
  #baseLine,
  #loader {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1; }
  #loader {
    z-index: 2; }
  #circles {
    width: 300px;
    height: 300px;
    margin: auto auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  /* ------------------- contents ---------------- */
  main {
    width: 100%;
    background-color: #fff;
    z-index: 1;
    margin-top: 100vh;
    padding-top: 60px; }
  #canvasContainer {
    transform: translateY(-100px); }
  #parts {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0; }
    #parts #symbol {
      width: 497px;
      height: 497px;
      max-width: 497px;
      max-height: 497px;
      width: 60%;
      height: calc(100vw * 0.6); }
      #parts #symbol img {
        width: 100%;
        height: auto; }
    #parts #icoScroll {
      width: 25px;
      height: 42px;
      border: 1px solid #fff;
      border-radius: 12px;
      position: absolute;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      transition: all .7s; }
      #parts #icoScroll span {
        font-size: 11px;
        color: #fff;
        display: block;
        width: 32px;
        height: 22px;
        position: absolute;
        bottom: -24px;
        left: -6px;
        letter-spacing: .2em;
        transition: all .4s; }
      #parts #icoScroll:after {
        content: "";
        display: block;
        width: 4px;
        height: 7px;
        border-radius: 3px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        animation: kurukuru 2.5s infinite ease-out; }
  @keyframes kurukuru {
    80% {
      opacity: 1; }
    100% {
      top: 26px;
      opacity: 0; } }
      #parts #icoScroll:hover {
        background-color: rgba(255, 255, 255, 0.6);
        /*
			&:after{
			//	animation: kurukuru 2s infinite ease-out;
			}

			@keyframes kurukuru{
				50%{
					top: 10px;
				}
			}
			*/ }
        #parts #icoScroll:hover span {
          bottom: -34px;
          opacity: 0; }
  #trend {
    width: 90%;
    margin: 10px auto 0; }
    #trend h2 {
      font-size: 36px;
      line-height: 1;
      text-align: center;
      font-weight: 400; }
    #trend #trendBanner {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      #trend #trendBanner .trendPhoto {
        margin-top: 10px;
        width: 100%; }
        #trend #trendBanner .trendPhoto#tPhoto2, #trend #trendBanner .trendPhoto#tPhoto3 {
          width: calc(50% - 10px); }
        #trend #trendBanner .trendPhoto#tPhoto5 .tImg {
          width: calc(50% - 10px); }
        #trend #trendBanner .trendPhoto .tImg {
          position: relative;
          line-height: 1; }
          #trend #trendBanner .trendPhoto .tImg a {
            display: block;
            line-height: 1;
            position: relative;
            background-color: #71bd29;
            text-decoration: none;
            overflow: hidden; }
            #trend #trendBanner .trendPhoto .tImg a img {
              width: 100%;
              height: auto; }
            #trend #trendBanner .trendPhoto .tImg a .tag {
              font-size: 18px;
              line-height: 1;
              z-index: 2;
              color: #fff;
              height: 18px;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all .3s; }
            #trend #trendBanner .trendPhoto .tImg a .hovItem {
              width: 100%;
              height: 100%;
              background-color: #71bd29;
              position: absolute;
              top: 100%;
              left: 0;
              color: #fff;
              padding: 20px 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              transition: all .3s; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .title {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem p {
                font-size: 13px;
                line-height: 1.6;
                margin-top: 1em;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .more {
                width: 26px;
                margin: 0 0 0 auto;
                text-align: center;
                border-bottom: 1px solid #fff;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                position: absolute;
                bottom: 10px;
                right: 10px;
                padding-bottom: 2px; }
            #trend #trendBanner .trendPhoto .tImg a:hover .tag {
              top: -100%; }
            #trend #trendBanner .trendPhoto .tImg a:hover .hovItem {
              top: 0; }
        #trend #trendBanner .trendPhoto#tPhoto1, #trend #trendBanner .trendPhoto#tPhoto4 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
          #trend #trendBanner .trendPhoto#tPhoto1 .tImg, #trend #trendBanner .trendPhoto#tPhoto4 .tImg {
            width: calc(50% - 10px);
            height: calc(50% - 5px);
            margin-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto2, #trend #trendBanner .trendPhoto#tPhoto3 {
          padding-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto5 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%; }
          #trend #trendBanner .trendPhoto#tPhoto5 .tImg {
            width: calc(25% - 14px);
            margin-top: 10px;
            width: calc(50% - 10px); }
        #trend #trendBanner .trendPhoto .img11, #trend #trendBanner .trendPhoto img13, #trend #trendBanner .trendPhoto #tPhoto3 {
          transition-delay: .6s; }
        #trend #trendBanner .trendPhoto .img12, #trend #trendBanner .trendPhoto img14, #trend #trendBanner .trendPhoto .img41, #trend #trendBanner .trendPhoto .img43 {
          transition-delay: .3s; }
  #sect2 {
    width: 100%;
    margin-top: 130px;
    margin-top: 50px; }
    #sect2 #sect2Inner {
      display: flex;
      flex-wrap: wrap; }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        width: calc(100% / 3); }
        #sect2 #sect2Inner #item1 a, #sect2 #sect2Inner #item3 a, #sect2 #sect2Inner #item4 a, #sect2 #sect2Inner #item5 a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          transition: all .4s;
          background-color: rgba(0, 0, 0, 0);
          overflow: hidden;
          text-decoration: none; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:after, #sect2 #sect2Inner #item5 a:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            position: absolute;
            background-color: rgba(113, 189, 41, 0.6);
            transition: all .3s;
            left: 0; }
          #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:before {
            top: 0; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item5 a:after {
            bottom: 0; }
          #sect2 #sect2Inner #item1 a .img, #sect2 #sect2Inner #item1 a .txt, #sect2 #sect2Inner #item3 a .img, #sect2 #sect2Inner #item3 a .txt, #sect2 #sect2Inner #item4 a .img, #sect2 #sect2Inner #item4 a .txt, #sect2 #sect2Inner #item5 a .img, #sect2 #sect2Inner #item5 a .txt {
            position: absolute;
            font-weight: 400;
            transition: all .3s;
            color: #fff; }
            #sect2 #sect2Inner #item1 a .img span, #sect2 #sect2Inner #item1 a .txt span, #sect2 #sect2Inner #item3 a .img span, #sect2 #sect2Inner #item3 a .txt span, #sect2 #sect2Inner #item4 a .img span, #sect2 #sect2Inner #item4 a .txt span, #sect2 #sect2Inner #item5 a .img span, #sect2 #sect2Inner #item5 a .txt span {
              font-size: 36px;
              line-height: 1;
              display: block; }
              #sect2 #sect2Inner #item1 a .img span:nth-of-type(2), #sect2 #sect2Inner #item1 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item3 a .img span:nth-of-type(2), #sect2 #sect2Inner #item3 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item4 a .img span:nth-of-type(2), #sect2 #sect2Inner #item4 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item5 a .img span:nth-of-type(2), #sect2 #sect2Inner #item5 a .txt span:nth-of-type(2) {
                font-size: 18px;
                margin-top: 15px; }
            #sect2 #sect2Inner #item1 a .img span, #sect2 #sect2Inner #item1 a .txt span, #sect2 #sect2Inner #item3 a .img span, #sect2 #sect2Inner #item3 a .txt span, #sect2 #sect2Inner #item4 a .img span, #sect2 #sect2Inner #item4 a .txt span, #sect2 #sect2Inner #item5 a .img span, #sect2 #sect2Inner #item5 a .txt span {
              font-size: 20px;
              line-height: 1;
              display: block; }
              #sect2 #sect2Inner #item1 a .img span:nth-of-type(2), #sect2 #sect2Inner #item1 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item3 a .img span:nth-of-type(2), #sect2 #sect2Inner #item3 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item4 a .img span:nth-of-type(2), #sect2 #sect2Inner #item4 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item5 a .img span:nth-of-type(2), #sect2 #sect2Inner #item5 a .txt span:nth-of-type(2) {
                font-size: 12px;
                margin-top: 8px; }
          #sect2 #sect2Inner #item1 a:hover, #sect2 #sect2Inner #item3 a:hover, #sect2 #sect2Inner #item4 a:hover, #sect2 #sect2Inner #item5 a:hover {
            background-color: rgba(0, 0, 0, 0.6); }
            #sect2 #sect2Inner #item1 a:hover:after, #sect2 #sect2Inner #item1 a:hover:before, #sect2 #sect2Inner #item3 a:hover:after, #sect2 #sect2Inner #item3 a:hover:before, #sect2 #sect2Inner #item4 a:hover:after, #sect2 #sect2Inner #item4 a:hover:before, #sect2 #sect2Inner #item5 a:hover:after, #sect2 #sect2Inner #item5 a:hover:before {
              height: 30%; }
            #sect2 #sect2Inner #item1 a:hover .img, #sect2 #sect2Inner #item1 a:hover .txt, #sect2 #sect2Inner #item3 a:hover .img, #sect2 #sect2Inner #item3 a:hover .txt, #sect2 #sect2Inner #item4 a:hover .img, #sect2 #sect2Inner #item4 a:hover .txt, #sect2 #sect2Inner #item5 a:hover .img, #sect2 #sect2Inner #item5 a:hover .txt {
              top: 50%;
              left: 0;
              width: 100%;
              padding-left: 20px;
              transform: translateY(-50%); }
              #sect2 #sect2Inner #item1 a:hover .img:after, #sect2 #sect2Inner #item1 a:hover .txt:after, #sect2 #sect2Inner #item3 a:hover .img:after, #sect2 #sect2Inner #item3 a:hover .txt:after, #sect2 #sect2Inner #item4 a:hover .img:after, #sect2 #sect2Inner #item4 a:hover .txt:after, #sect2 #sect2Inner #item5 a:hover .img:after, #sect2 #sect2Inner #item5 a:hover .txt:after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border-top: 3px solid #71bd29;
                border-right: 3px solid #71bd29;
                transform: rotate(45deg);
                position: absolute;
                top: 40%;
                right: 35px;
                animation: arrAni 1.5s infinite; }
  @keyframes arrAni {
    20% {
      right: 20px; } }
      #sect2 #sect2Inner #item2 {
        width: calc((100% / 3) * 2); }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item2 {
        height: 619px; }
      #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        height: 749px; }
      #sect2 #sect2Inner .sect2Item {
        background-size: cover;
        background-position: 50% 50%; }
      #sect2 #sect2Inner #item1 {
        transition-delay: .4s; }
        #sect2 #sect2Inner #item1 h2.txt {
          position: absolute;
          top: 10%;
          left: calc(100% - 140px);
          color: #fff;
          font-size: 16px;
          height: 38px;
          display: flex;
          align-items: center;
          line-height: 1; }
          #sect2 #sect2Inner #item1 h2.txt span {
            font-size: 16px; }
            #sect2 #sect2Inner #item1 h2.txt span:nth-of-type(2) {
              margin-left: 10px;
              margin-top: 0; }
      #sect2 #sect2Inner #item2 {
        background-image: url(../images/sect2-img2.png);
        background-position: right bottom;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        #sect2 #sect2Inner #item2 #item2Inner {
          width: 80%; }
          #sect2 #sect2Inner #item2 #item2Inner .title {
            font-size: 36px;
            line-height: 1;
            color: #fff;
            font-weight: 400; }
          #sect2 #sect2Inner #item2 #item2Inner #newsList {
            list-style: none;
            margin-top: 50px; }
            #sect2 #sect2Inner #item2 #item2Inner #newsList li {
              display: flex; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li .tag {
                font-size: 16px;
                line-height: 1;
                color: #fff;
                font-weight: 700;
                color: #fff;
                width: 75px;
                margin-top: -10px; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #fff;
                color: #fff;
                text-decoration: none;
                padding: 15px 0;
                position: relative;
                z-index: 1;
                overflow: hidden; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:after {
                  content: "";
                  display: block;
                  width: 120%;
                  height: 180%;
                  background-color: rgba(113, 189, 41, 0.6);
                  transform: rotate(3deg);
                  position: absolute;
                  top: 145%;
                  left: -5%;
                  z-index: -1;
                  transition: all .7s; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:hover:after {
                  top: -35%; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a span {
                  position: relative;
                  z-index: 1;
                  display: block; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.newsTitle {
                    font-size: 15px;
                    padding-left: 5px; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.date {
                    padding-right: 5px;
                    font-size: 30px;
                    font-weight: 700; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li:nth-of-type(1) a {
                border-top: 1px solid #fff; }
          #sect2 #sect2Inner #item2 #item2Inner .more {
            width: 15px;
            height: 108px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden; }
            #sect2 #sect2Inner #item2 #item2Inner .more a {
              display: block;
              width: 15px;
              height: 108px;
              position: relative;
              overflow: hidden; }
              #sect2 #sect2Inner #item2 #item2Inner .more a img {
                display: block;
                position: absolute;
                top: 0;
                left: -15px;
                transition: all .4s; }
              #sect2 #sect2Inner #item2 #item2Inner .more a:hover img {
                left: 0; }
      #sect2 #sect2Inner #item3 {
        background-image: url(../images/sect2-img3.jpg);
        transition-delay: .6s; }
        #sect2 #sect2Inner #item3 .img {
          top: 20%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item4 {
        background-image: url(../images/sect2-img4.jpg);
        transition-delay: .3s; }
        #sect2 #sect2Inner #item4 a:hover {
          background-color: rgba(255, 255, 255, 0.6); }
        #sect2 #sect2Inner #item4 h2.img {
          top: 45%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item5 {
        background-image: url(../images/sect2-img5.jpg); }
        #sect2 #sect2Inner #item5 .img {
          top: 60%;
          left: 10%; }
      #sect2 #sect2Inner #item1 {
        order: 1;
        width: 50%;
        height: calc(100vw / 2); }
        #sect2 #sect2Inner #item1 a h2.txt {
          left: 20%;
          top: 5%; }
          #sect2 #sect2Inner #item1 a h2.txt span {
            font-size: 13px; }
            #sect2 #sect2Inner #item1 a h2.txt span:nth-of-type(2) {
              width: 25px;
              height: 25px; }
              #sect2 #sect2Inner #item1 a h2.txt span:nth-of-type(2) img {
                width: 100%;
                height: auto; }
      #sect2 #sect2Inner #item2 {
        order: 0;
        width: 100%;
        height: auto;
        padding: 30px 0; }
        #sect2 #sect2Inner #item2 #item2Inner ul#newsList {
          margin-top: 15px; }
          #sect2 #sect2Inner #item2 #item2Inner ul#newsList li {
            display: block;
            margin-top: 15px; }
            #sect2 #sect2Inner #item2 #item2Inner ul#newsList li:nth-of-type(1) a {
              border-top: 0px solid transparent; }
            #sect2 #sect2Inner #item2 #item2Inner ul#newsList li .tag {
              margin: 0; }
            #sect2 #sect2Inner #item2 #item2Inner ul#newsList li a {
              display: block;
              padding: 5px 0; }
              #sect2 #sect2Inner #item2 #item2Inner ul#newsList li a .newsTitle {
                font-size: 14px; }
              #sect2 #sect2Inner #item2 #item2Inner ul#newsList li a .date {
                font-size: 16px;
                display: block;
                text-align: right; }
      #sect2 #sect2Inner #item3 {
        order: 2;
        width: 50%;
        height: calc(100vw / 2); }
      #sect2 #sect2Inner #item4 {
        order: 3;
        width: 50%;
        height: calc(100vw / 2); }
      #sect2 #sect2Inner #item5 {
        order: 4;
        width: 50%;
        height: calc(100vw / 2); }
  #sect3 {
    margin-top: 100px;
    margin-top: 40px; }
    #sect3 ul {
      max-width: 1200px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      list-style: none; }
      #sect3 ul li {
        width: calc(100% / 3); }
        #sect3 ul li:not(:last-of-type) {
          margin-right: 1px; }
        #sect3 ul li a {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 265px;
          position: relative;
          background-color: #f2f2f2;
          z-index: 1;
          color: #000;
          text-decoration: none;
          height: 150px; }
          #sect3 ul li a .icon {
            width: 45%; }
            #sect3 ul li a .icon svg {
              width: 100%;
              height: auto; }
          #sect3 ul li a .txt {
            font-size: 16px;
            line-height: 1; }
  .fbWrap {
    padding: 30px 20px 0; }
    .fbWrap .fb_iframe_widget,
    .fbWrap .fb_iframe_widget span,
    .fbWrap .fb_iframe_widget iframe[style] {
      width: 100% !important; } }

@media screen and (min-width: 651px) and (max-width: 999px) {
  /*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  article, aside, footer, nav, section, figcaption, figure, main {
    display: block; }
  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  sub {
    bottom: -.25em; }
  sup {
    top: -.5em; }
  img {
    border-style: none;
    vertical-align: top; }
  table {
    border-collapse: collapse; }
  button, input, select, textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button; }
  textarea {
    overflow: auto;
    resize: vertical; }
  [type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  a, area, button, input, label, select, summary, textarea, [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation; }
  /* --------------- variables --------------------- */
  /* Break Point */
  /* Color */
  /*サイトカラー*/
  /*テキストに使うフォントカラー*/
  /*テキスト内で使うリンクカラー*/
  /*日本語用（）*/
  /*英語用*/
  #screen {
    width: 100%;
    position: relative;
    height: 100vh; }
    #screen .topImg {
      width: 100%;
      height: 100vh; }
      #screen .topImg img {
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto; }
        #screen .topImg img.bg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translateX(-50%) translateY(-50%);
          transition: all 20s; }
          #screen .topImg img.bg.CurrentImg {
            max-width: none;
            max-height: none;
            transform: translateX(-50%) translateY(-50%) scale(1.5); }
  #wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1; }
  /* ----------------------- SVG ---------------------- */
  #mainV {
    position: relative;
    width: 100%;
    height: 100%; }
    #mainV svg {
      transform: rotate(-90deg); }
  .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .circle {
    fill: transparent;
    stroke: #71bd29;
    stroke-width: 8;
    stroke-linecap: round; }
  .circle2 {
    fill: transparent;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 0 6;
    stroke-linecap: round; }
  #baseLine,
  #loader {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1; }
  #loader {
    z-index: 2; }
  #circles {
    width: 300px;
    height: 300px;
    margin: auto auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  /* ------------------- contents ---------------- */
  main {
    width: 100%;
    background-color: #fff;
    z-index: 1;
    margin-top: 100vh;
    padding-top: 60px; }
  #canvasContainer {
    transform: translateY(-100px); }
  #parts {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0; }
    #parts #symbol {
      width: 497px;
      height: 497px;
      max-width: 497px;
      max-height: 497px;
      width: 60%;
      height: calc(100vw * 0.6); }
      #parts #symbol img {
        width: 100%;
        height: auto; }
    #parts #icoScroll {
      width: 25px;
      height: 42px;
      border: 1px solid #fff;
      border-radius: 12px;
      position: absolute;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      transition: all .7s; }
      #parts #icoScroll span {
        font-size: 11px;
        color: #fff;
        display: block;
        width: 32px;
        height: 22px;
        position: absolute;
        bottom: -24px;
        left: -6px;
        letter-spacing: .2em;
        transition: all .4s; }
      #parts #icoScroll:after {
        content: "";
        display: block;
        width: 4px;
        height: 7px;
        border-radius: 3px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        animation: kurukuru 2.5s infinite ease-out; }
  @keyframes kurukuru {
    80% {
      opacity: 1; }
    100% {
      top: 26px;
      opacity: 0; } }
      #parts #icoScroll:hover {
        background-color: rgba(255, 255, 255, 0.6);
        /*
			&:after{
			//	animation: kurukuru 2s infinite ease-out;
			}

			@keyframes kurukuru{
				50%{
					top: 10px;
				}
			}
			*/ }
        #parts #icoScroll:hover span {
          bottom: -34px;
          opacity: 0; }
  #trend {
    width: 90%;
    margin: 10px auto 0; }
    #trend h2 {
      font-size: 36px;
      line-height: 1;
      text-align: center;
      font-weight: 400; }
    #trend #trendBanner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px; }
      #trend #trendBanner .trendPhoto {
        width: calc(50% - 10px);
        margin-top: 20px; }
        #trend #trendBanner .trendPhoto .tImg {
          position: relative;
          line-height: 1; }
          #trend #trendBanner .trendPhoto .tImg a {
            display: block;
            line-height: 1;
            position: relative;
            background-color: #71bd29;
            text-decoration: none;
            overflow: hidden; }
            #trend #trendBanner .trendPhoto .tImg a img {
              width: 100%;
              height: auto; }
            #trend #trendBanner .trendPhoto .tImg a .tag {
              font-size: 18px;
              line-height: 1;
              z-index: 2;
              color: #fff;
              height: 18px;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all .3s; }
            #trend #trendBanner .trendPhoto .tImg a .hovItem {
              width: 100%;
              height: 100%;
              background-color: #71bd29;
              position: absolute;
              top: 100%;
              left: 0;
              color: #fff;
              padding: 20px 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              transition: all .3s; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .title {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem p {
                font-size: 13px;
                line-height: 1.6;
                margin-top: 1em;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .more {
                width: 26px;
                margin: 0 0 0 auto;
                text-align: center;
                border-bottom: 1px solid #fff;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                position: absolute;
                bottom: 10px;
                right: 10px;
                padding-bottom: 2px; }
            #trend #trendBanner .trendPhoto .tImg a:hover .tag {
              top: -100%; }
            #trend #trendBanner .trendPhoto .tImg a:hover .hovItem {
              top: 0; }
        #trend #trendBanner .trendPhoto#tPhoto1, #trend #trendBanner .trendPhoto#tPhoto4 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
          #trend #trendBanner .trendPhoto#tPhoto1 .tImg, #trend #trendBanner .trendPhoto#tPhoto4 .tImg {
            width: calc(50% - 10px);
            height: calc(50% - 5px);
            margin-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto2, #trend #trendBanner .trendPhoto#tPhoto3 {
          padding-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto5 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%; }
          #trend #trendBanner .trendPhoto#tPhoto5 .tImg {
            width: calc(25% - 14px);
            margin-top: 10px; }
        #trend #trendBanner .trendPhoto .img11, #trend #trendBanner .trendPhoto img13, #trend #trendBanner .trendPhoto #tPhoto3 {
          transition-delay: .6s; }
        #trend #trendBanner .trendPhoto .img12, #trend #trendBanner .trendPhoto img14, #trend #trendBanner .trendPhoto .img41, #trend #trendBanner .trendPhoto .img43 {
          transition-delay: .3s; }
  #sect2 {
    width: 100%;
    margin-top: 130px;
    margin-top: 50px; }
    #sect2 #sect2Inner {
      display: flex;
      flex-wrap: wrap; }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        width: calc(100% / 3); }
        #sect2 #sect2Inner #item1 a, #sect2 #sect2Inner #item3 a, #sect2 #sect2Inner #item4 a, #sect2 #sect2Inner #item5 a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          transition: all .4s;
          background-color: rgba(0, 0, 0, 0);
          overflow: hidden;
          text-decoration: none; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:after, #sect2 #sect2Inner #item5 a:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            position: absolute;
            background-color: rgba(113, 189, 41, 0.6);
            transition: all .3s;
            left: 0; }
          #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:before {
            top: 0; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item5 a:after {
            bottom: 0; }
          #sect2 #sect2Inner #item1 a .img, #sect2 #sect2Inner #item1 a .txt, #sect2 #sect2Inner #item3 a .img, #sect2 #sect2Inner #item3 a .txt, #sect2 #sect2Inner #item4 a .img, #sect2 #sect2Inner #item4 a .txt, #sect2 #sect2Inner #item5 a .img, #sect2 #sect2Inner #item5 a .txt {
            position: absolute;
            font-weight: 400;
            transition: all .3s;
            color: #fff; }
            #sect2 #sect2Inner #item1 a .img span, #sect2 #sect2Inner #item1 a .txt span, #sect2 #sect2Inner #item3 a .img span, #sect2 #sect2Inner #item3 a .txt span, #sect2 #sect2Inner #item4 a .img span, #sect2 #sect2Inner #item4 a .txt span, #sect2 #sect2Inner #item5 a .img span, #sect2 #sect2Inner #item5 a .txt span {
              font-size: 36px;
              line-height: 1;
              display: block; }
              #sect2 #sect2Inner #item1 a .img span:nth-of-type(2), #sect2 #sect2Inner #item1 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item3 a .img span:nth-of-type(2), #sect2 #sect2Inner #item3 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item4 a .img span:nth-of-type(2), #sect2 #sect2Inner #item4 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item5 a .img span:nth-of-type(2), #sect2 #sect2Inner #item5 a .txt span:nth-of-type(2) {
                font-size: 18px;
                margin-top: 15px; }
          #sect2 #sect2Inner #item1 a:hover, #sect2 #sect2Inner #item3 a:hover, #sect2 #sect2Inner #item4 a:hover, #sect2 #sect2Inner #item5 a:hover {
            background-color: rgba(0, 0, 0, 0.6); }
            #sect2 #sect2Inner #item1 a:hover:after, #sect2 #sect2Inner #item1 a:hover:before, #sect2 #sect2Inner #item3 a:hover:after, #sect2 #sect2Inner #item3 a:hover:before, #sect2 #sect2Inner #item4 a:hover:after, #sect2 #sect2Inner #item4 a:hover:before, #sect2 #sect2Inner #item5 a:hover:after, #sect2 #sect2Inner #item5 a:hover:before {
              height: 30%; }
            #sect2 #sect2Inner #item1 a:hover .img, #sect2 #sect2Inner #item1 a:hover .txt, #sect2 #sect2Inner #item3 a:hover .img, #sect2 #sect2Inner #item3 a:hover .txt, #sect2 #sect2Inner #item4 a:hover .img, #sect2 #sect2Inner #item4 a:hover .txt, #sect2 #sect2Inner #item5 a:hover .img, #sect2 #sect2Inner #item5 a:hover .txt {
              top: 50%;
              left: 0;
              width: 100%;
              padding-left: 20px;
              transform: translateY(-50%); }
              #sect2 #sect2Inner #item1 a:hover .img:after, #sect2 #sect2Inner #item1 a:hover .txt:after, #sect2 #sect2Inner #item3 a:hover .img:after, #sect2 #sect2Inner #item3 a:hover .txt:after, #sect2 #sect2Inner #item4 a:hover .img:after, #sect2 #sect2Inner #item4 a:hover .txt:after, #sect2 #sect2Inner #item5 a:hover .img:after, #sect2 #sect2Inner #item5 a:hover .txt:after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border-top: 3px solid #71bd29;
                border-right: 3px solid #71bd29;
                transform: rotate(45deg);
                position: absolute;
                top: 40%;
                right: 35px;
                animation: arrAni 1.5s infinite; }
  @keyframes arrAni {
    20% {
      right: 20px; } }
      #sect2 #sect2Inner #item2 {
        width: calc((100% / 3) * 2); }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item2 {
        height: 619px; }
      #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        height: 749px; }
      #sect2 #sect2Inner .sect2Item {
        background-size: cover;
        background-position: 50% 50%; }
      #sect2 #sect2Inner #item1 {
        transition-delay: .4s; }
        #sect2 #sect2Inner #item1 h2.txt {
          position: absolute;
          top: 10%;
          left: calc(100% - 140px);
          color: #fff;
          font-size: 16px;
          height: 38px;
          display: flex;
          align-items: center;
          line-height: 1; }
          #sect2 #sect2Inner #item1 h2.txt span {
            font-size: 16px; }
            #sect2 #sect2Inner #item1 h2.txt span:nth-of-type(2) {
              margin-left: 10px;
              margin-top: 0; }
      #sect2 #sect2Inner #item2 {
        background-image: url(../images/sect2-img2.png);
        background-position: right bottom;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        #sect2 #sect2Inner #item2 #item2Inner {
          width: 80%; }
          #sect2 #sect2Inner #item2 #item2Inner .title {
            font-size: 36px;
            line-height: 1;
            color: #fff;
            font-weight: 400; }
          #sect2 #sect2Inner #item2 #item2Inner #newsList {
            list-style: none;
            margin-top: 50px; }
            #sect2 #sect2Inner #item2 #item2Inner #newsList li {
              display: flex; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li .tag {
                font-size: 16px;
                line-height: 1;
                color: #fff;
                font-weight: 700;
                color: #fff;
                width: 75px;
                margin-top: -10px; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #fff;
                color: #fff;
                text-decoration: none;
                padding: 15px 0;
                position: relative;
                z-index: 1;
                overflow: hidden; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:after {
                  content: "";
                  display: block;
                  width: 120%;
                  height: 180%;
                  background-color: rgba(113, 189, 41, 0.6);
                  transform: rotate(3deg);
                  position: absolute;
                  top: 145%;
                  left: -5%;
                  z-index: -1;
                  transition: all .7s; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:hover:after {
                  top: -35%; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a span {
                  position: relative;
                  z-index: 1;
                  display: block; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.newsTitle {
                    font-size: 15px;
                    padding-left: 5px; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.date {
                    padding-right: 5px;
                    font-size: 30px;
                    font-weight: 700; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li:nth-of-type(1) a {
                border-top: 1px solid #fff; }
          #sect2 #sect2Inner #item2 #item2Inner .more {
            width: 15px;
            height: 108px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden; }
            #sect2 #sect2Inner #item2 #item2Inner .more a {
              display: block;
              width: 15px;
              height: 108px;
              position: relative;
              overflow: hidden; }
              #sect2 #sect2Inner #item2 #item2Inner .more a img {
                display: block;
                position: absolute;
                top: 0;
                left: -15px;
                transition: all .4s; }
              #sect2 #sect2Inner #item2 #item2Inner .more a:hover img {
                left: 0; }
      #sect2 #sect2Inner #item3 {
        background-image: url(../images/sect2-img3.jpg);
        transition-delay: .6s; }
        #sect2 #sect2Inner #item3 .img {
          top: 20%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item4 {
        background-image: url(../images/sect2-img4.jpg);
        transition-delay: .3s; }
        #sect2 #sect2Inner #item4 a:hover {
          background-color: rgba(255, 255, 255, 0.6); }
        #sect2 #sect2Inner #item4 h2.img {
          top: 45%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item5 {
        background-image: url(../images/sect2-img5.jpg); }
        #sect2 #sect2Inner #item5 .img {
          top: 60%;
          left: 10%; }
      #sect2 #sect2Inner #item1 {
        order: 1;
        width: 50%;
        height: calc(100vw / 2); }
      #sect2 #sect2Inner #item2 {
        order: 0;
        width: 100%;
        height: auto;
        padding: 30px 0; }
      #sect2 #sect2Inner #item3 {
        order: 2;
        width: 50%;
        height: calc(100vw / 2); }
      #sect2 #sect2Inner #item4 {
        order: 3;
        width: 50%;
        height: calc(100vw / 2); }
      #sect2 #sect2Inner #item5 {
        order: 4;
        width: 50%;
        height: calc(100vw / 2); }
  #sect3 {
    margin-top: 100px;
    margin-top: 40px; }
    #sect3 ul {
      max-width: 1200px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      list-style: none; }
      #sect3 ul li {
        width: calc(100% / 3); }
        #sect3 ul li:not(:last-of-type) {
          margin-right: 1px; }
        #sect3 ul li a {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 265px;
          position: relative;
          background-color: #f2f2f2;
          z-index: 1;
          color: #000;
          text-decoration: none;
          height: 150px; }
          #sect3 ul li a .icon {
            width: 45%; }
            #sect3 ul li a .icon svg {
              width: 100%;
              height: auto; }
          #sect3 ul li a .txt {
            font-size: 16px;
            line-height: 1; }
  .fbWrap .fb_iframe_widget,
  .fbWrap .fb_iframe_widget span,
  .fbWrap .fb_iframe_widget iframe[style] {
    width: 100% !important; } }

@media print, screen and (min-width: 1000px) {
  /*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  article, aside, footer, nav, section, figcaption, figure, main {
    display: block; }
  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  sub {
    bottom: -.25em; }
  sup {
    top: -.5em; }
  img {
    border-style: none;
    vertical-align: top; }
  table {
    border-collapse: collapse; }
  button, input, select, textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button; }
  textarea {
    overflow: auto;
    resize: vertical; }
  [type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  a, area, button, input, label, select, summary, textarea, [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation; }
  /* --------------- variables --------------------- */
  /* Break Point */
  /* Color */
  /*サイトカラー*/
  /*テキストに使うフォントカラー*/
  /*テキスト内で使うリンクカラー*/
  /*日本語用（）*/
  /*英語用*/
  #screen {
    width: 100%;
    position: relative;
    height: 100vh; }
    #screen .topImg {
      width: 100%;
      height: 100vh; }
      #screen .topImg img {
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto; }
        #screen .topImg img.bg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translateX(-50%) translateY(-50%);
          transition: all 20s; }
          #screen .topImg img.bg.CurrentImg {
            max-width: none;
            max-height: none;
            transform: translateX(-50%) translateY(-50%) scale(1.5); }
  #wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1; }
  /* ----------------------- SVG ---------------------- */
  #mainV {
    position: relative;
    width: 100%;
    height: 100%; }
    #mainV svg {
      transform: rotate(-90deg); }
  .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .circle {
    fill: transparent;
    stroke: #71bd29;
    stroke-width: 8;
    stroke-linecap: round; }
  .circle2 {
    fill: transparent;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 0 6;
    stroke-linecap: round; }
  #baseLine,
  #loader {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1; }
  #loader {
    z-index: 2; }
  #circles {
    width: 300px;
    height: 300px;
    margin: auto auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
  /* ------------------- contents ---------------- */
  main {
    width: 100%;
    background-color: #fff;
    z-index: 1;
    margin-top: 100vh;
    padding-top: 60px; }
  #canvasContainer {
    transform: translateY(-100px); }
  #parts {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0; }
    #parts #symbol {
      width: 497px;
      height: 497px; }
    #parts #icoScroll {
      width: 25px;
      height: 42px;
      border: 1px solid #fff;
      border-radius: 12px;
      position: absolute;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      transition: all .7s; }
      #parts #icoScroll span {
        font-size: 11px;
        color: #fff;
        display: block;
        width: 32px;
        height: 22px;
        position: absolute;
        bottom: -24px;
        left: -6px;
        letter-spacing: .2em;
        transition: all .4s; }
      #parts #icoScroll:after {
        content: "";
        display: block;
        width: 4px;
        height: 7px;
        border-radius: 3px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        animation: kurukuru 2.5s infinite ease-out; }
  @keyframes kurukuru {
    80% {
      opacity: 1; }
    100% {
      top: 26px;
      opacity: 0; } }
      #parts #icoScroll:hover {
        background-color: rgba(255, 255, 255, 0.6);
        /*
			&:after{
			//	animation: kurukuru 2s infinite ease-out;
			}

			@keyframes kurukuru{
				50%{
					top: 10px;
				}
			}
			*/ }
        #parts #icoScroll:hover span {
          bottom: -34px;
          opacity: 0; }
  #trend {
    width: 85%;
    margin: 20px auto 0; }
    #trend h2 {
      font-size: 36px;
      line-height: 1;
      text-align: center;
      font-weight: 400; }
    #trend #trendBanner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px; }
      #trend #trendBanner .trendPhoto {
        width: calc(50% - 10px);
        margin-top: 10px; }
        #trend #trendBanner .trendPhoto .tImg {
          position: relative;
          line-height: 1; }
          #trend #trendBanner .trendPhoto .tImg a {
            display: block;
            line-height: 1;
            position: relative;
            background-color: #71bd29;
            text-decoration: none;
            overflow: hidden; }
            #trend #trendBanner .trendPhoto .tImg a img {
              width: 100%;
              height: auto; }
            #trend #trendBanner .trendPhoto .tImg a .tag {
              font-size: 18px;
              line-height: 1;
              z-index: 2;
              color: #fff;
              height: 18px;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all .3s; }
            #trend #trendBanner .trendPhoto .tImg a .hovItem {
              width: 100%;
              height: 100%;
              background-color: #71bd29;
              position: absolute;
              top: 100%;
              left: 0;
              color: #fff;
              padding: 20px 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              transition: all .3s; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .title {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem p {
                font-size: 13px;
                line-height: 1.6;
                margin-top: 1em;
                width: 100%;
                font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
              #trend #trendBanner .trendPhoto .tImg a .hovItem .more {
                width: 26px;
                margin: 0 0 0 auto;
                text-align: center;
                border-bottom: 1px solid #fff;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                position: absolute;
                bottom: 10px;
                right: 10px;
                padding-bottom: 2px; }
            #trend #trendBanner .trendPhoto .tImg a:hover .tag {
              top: -100%; }
            #trend #trendBanner .trendPhoto .tImg a:hover .hovItem {
              top: 0; }
        #trend #trendBanner .trendPhoto#tPhoto1, #trend #trendBanner .trendPhoto#tPhoto4 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
          #trend #trendBanner .trendPhoto#tPhoto1 .tImg, #trend #trendBanner .trendPhoto#tPhoto4 .tImg {
            width: calc(50% - 10px);
            height: calc(50% - 5px);
            margin-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto2, #trend #trendBanner .trendPhoto#tPhoto3 {
          padding-top: 10px; }
        #trend #trendBanner .trendPhoto#tPhoto5 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%; }
          #trend #trendBanner .trendPhoto#tPhoto5 .tImg {
            width: calc(25% - 14px);
            margin-top: 10px; }
        #trend #trendBanner .trendPhoto .img11, #trend #trendBanner .trendPhoto img13, #trend #trendBanner .trendPhoto #tPhoto3 {
          transition-delay: .6s; }
        #trend #trendBanner .trendPhoto .img12, #trend #trendBanner .trendPhoto img14, #trend #trendBanner .trendPhoto .img41, #trend #trendBanner .trendPhoto .img43 {
          transition-delay: .3s; }
  #sect2 {
    width: 100%;
    margin-top: 130px; }
    #sect2 #sect2Inner {
      display: flex;
      flex-wrap: wrap; }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        width: calc(100% / 3); }
        #sect2 #sect2Inner #item1 a, #sect2 #sect2Inner #item3 a, #sect2 #sect2Inner #item4 a, #sect2 #sect2Inner #item5 a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          transition: all .4s;
          background-color: rgba(0, 0, 0, 0);
          overflow: hidden;
          text-decoration: none; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:after, #sect2 #sect2Inner #item5 a:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            position: absolute;
            background-color: rgba(113, 189, 41, 0.6);
            transition: all .3s;
            left: 0; }
          #sect2 #sect2Inner #item1 a:before, #sect2 #sect2Inner #item3 a:before, #sect2 #sect2Inner #item4 a:before, #sect2 #sect2Inner #item5 a:before {
            top: 0; }
          #sect2 #sect2Inner #item1 a:after, #sect2 #sect2Inner #item3 a:after, #sect2 #sect2Inner #item4 a:after, #sect2 #sect2Inner #item5 a:after {
            bottom: 0; }
          #sect2 #sect2Inner #item1 a .img, #sect2 #sect2Inner #item1 a .txt, #sect2 #sect2Inner #item3 a .img, #sect2 #sect2Inner #item3 a .txt, #sect2 #sect2Inner #item4 a .img, #sect2 #sect2Inner #item4 a .txt, #sect2 #sect2Inner #item5 a .img, #sect2 #sect2Inner #item5 a .txt {
            position: absolute;
            font-weight: 400;
            transition: all .3s;
            color: #fff; }
            #sect2 #sect2Inner #item1 a .img span, #sect2 #sect2Inner #item1 a .txt span, #sect2 #sect2Inner #item3 a .img span, #sect2 #sect2Inner #item3 a .txt span, #sect2 #sect2Inner #item4 a .img span, #sect2 #sect2Inner #item4 a .txt span, #sect2 #sect2Inner #item5 a .img span, #sect2 #sect2Inner #item5 a .txt span {
              font-size: 36px;
              line-height: 1;
              display: block; }
              #sect2 #sect2Inner #item1 a .img span:nth-of-type(2), #sect2 #sect2Inner #item1 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item3 a .img span:nth-of-type(2), #sect2 #sect2Inner #item3 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item4 a .img span:nth-of-type(2), #sect2 #sect2Inner #item4 a .txt span:nth-of-type(2), #sect2 #sect2Inner #item5 a .img span:nth-of-type(2), #sect2 #sect2Inner #item5 a .txt span:nth-of-type(2) {
                font-size: 18px;
                margin-top: 15px; }
          #sect2 #sect2Inner #item1 a:hover, #sect2 #sect2Inner #item3 a:hover, #sect2 #sect2Inner #item4 a:hover, #sect2 #sect2Inner #item5 a:hover {
            background-color: rgba(0, 0, 0, 0.6); }
            #sect2 #sect2Inner #item1 a:hover:after, #sect2 #sect2Inner #item1 a:hover:before, #sect2 #sect2Inner #item3 a:hover:after, #sect2 #sect2Inner #item3 a:hover:before, #sect2 #sect2Inner #item4 a:hover:after, #sect2 #sect2Inner #item4 a:hover:before, #sect2 #sect2Inner #item5 a:hover:after, #sect2 #sect2Inner #item5 a:hover:before {
              height: 30%; }
            #sect2 #sect2Inner #item1 a:hover .img, #sect2 #sect2Inner #item1 a:hover .txt, #sect2 #sect2Inner #item3 a:hover .img, #sect2 #sect2Inner #item3 a:hover .txt, #sect2 #sect2Inner #item4 a:hover .img, #sect2 #sect2Inner #item4 a:hover .txt, #sect2 #sect2Inner #item5 a:hover .img, #sect2 #sect2Inner #item5 a:hover .txt {
              top: 50%;
              left: 0;
              width: 100%;
              padding-left: 20px;
              transform: translateY(-50%); }
              #sect2 #sect2Inner #item1 a:hover .img:after, #sect2 #sect2Inner #item1 a:hover .txt:after, #sect2 #sect2Inner #item3 a:hover .img:after, #sect2 #sect2Inner #item3 a:hover .txt:after, #sect2 #sect2Inner #item4 a:hover .img:after, #sect2 #sect2Inner #item4 a:hover .txt:after, #sect2 #sect2Inner #item5 a:hover .img:after, #sect2 #sect2Inner #item5 a:hover .txt:after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border-top: 3px solid #71bd29;
                border-right: 3px solid #71bd29;
                transform: rotate(45deg);
                position: absolute;
                top: 40%;
                right: 35px;
                animation: arrAni 1.5s infinite; }
  @keyframes arrAni {
    20% {
      right: 20px; } }
      #sect2 #sect2Inner #item2 {
        width: calc((100% / 3) * 2); }
      #sect2 #sect2Inner #item1, #sect2 #sect2Inner #item2 {
        height: 619px; }
      #sect2 #sect2Inner #item3, #sect2 #sect2Inner #item4, #sect2 #sect2Inner #item5 {
        height: 749px; }
      #sect2 #sect2Inner .sect2Item {
        background-size: cover;
        background-position: 50% 50%; }
      #sect2 #sect2Inner #item1 {
        transition-delay: .4s; }
        #sect2 #sect2Inner #item1 h2.txt {
          position: absolute;
          top: 10%;
          left: calc(100% - 140px);
          color: #fff;
          font-size: 16px;
          height: 38px;
          display: flex;
          align-items: center;
          line-height: 1; }
          #sect2 #sect2Inner #item1 h2.txt span {
            font-size: 16px; }
            #sect2 #sect2Inner #item1 h2.txt span:nth-of-type(2) {
              margin-left: 10px;
              margin-top: 0; }
      #sect2 #sect2Inner #item2 {
        background-image: url(../images/sect2-img2.png);
        background-position: right bottom;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        #sect2 #sect2Inner #item2 #item2Inner {
          width: 80%; }
          #sect2 #sect2Inner #item2 #item2Inner .title {
            font-size: 36px;
            line-height: 1;
            color: #fff;
            font-weight: 400; }
          #sect2 #sect2Inner #item2 #item2Inner #newsList {
            list-style: none;
            margin-top: 50px; }
            #sect2 #sect2Inner #item2 #item2Inner #newsList li {
              display: flex; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li .tag {
                font-size: 16px;
                line-height: 1;
                color: #fff;
                font-weight: 700;
                color: #fff;
                width: 75px;
                margin-top: -10px; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #fff;
                color: #fff;
                text-decoration: none;
                padding: 15px 0;
                position: relative;
                z-index: 1;
                overflow: hidden; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:after {
                  content: "";
                  display: block;
                  width: 120%;
                  height: 180%;
                  background-color: rgba(113, 189, 41, 0.6);
                  transform: rotate(3deg);
                  position: absolute;
                  top: 145%;
                  left: -5%;
                  z-index: -1;
                  transition: all .7s; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a:hover:after {
                  top: -35%; }
                #sect2 #sect2Inner #item2 #item2Inner #newsList li a span {
                  position: relative;
                  z-index: 1;
                  display: block; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.newsTitle {
                    font-size: 15px;
                    padding-left: 5px; }
                  #sect2 #sect2Inner #item2 #item2Inner #newsList li a span.date {
                    padding-right: 5px;
                    font-size: 30px;
                    font-weight: 700; }
              #sect2 #sect2Inner #item2 #item2Inner #newsList li:nth-of-type(1) a {
                border-top: 1px solid #fff; }
          #sect2 #sect2Inner #item2 #item2Inner .more {
            width: 15px;
            height: 108px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden; }
            #sect2 #sect2Inner #item2 #item2Inner .more a {
              display: block;
              width: 15px;
              height: 108px;
              position: relative;
              overflow: hidden; }
              #sect2 #sect2Inner #item2 #item2Inner .more a img {
                display: block;
                position: absolute;
                top: 0;
                left: -15px;
                transition: all .4s; }
              #sect2 #sect2Inner #item2 #item2Inner .more a:hover img {
                left: 0; }
      #sect2 #sect2Inner #item3 {
        background-image: url(../images/sect2-img3.jpg);
        transition-delay: .6s; }
        #sect2 #sect2Inner #item3 .img {
          top: 20%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item4 {
        background-image: url(../images/sect2-img4.jpg);
        transition-delay: .3s; }
        #sect2 #sect2Inner #item4 a:hover {
          background-color: rgba(255, 255, 255, 0.6); }
        #sect2 #sect2Inner #item4 h2.img {
          top: 45%;
          left: 10%;
          color: #000; }
      #sect2 #sect2Inner #item5 {
        background-image: url(../images/sect2-img5.jpg); }
        #sect2 #sect2Inner #item5 .img {
          top: 60%;
          left: 10%; }
  #sect3 {
    margin-top: 100px; }
    #sect3 ul {
      max-width: 1200px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      list-style: none; }
      #sect3 ul li {
        width: calc(100% / 3);
        overflow: hidden;
        /*
				&:nth-of-type(1){
					a{
						.icon{
							width: 78px;
							height: 105px;

							&:before{
								background-image: url(../images/home/icon-company-w.png);
								background-repeat: no-repeat;

							}
						}
					}
				}

				&:nth-of-type(2){
					a{
						.icon{
							width: 71px;
							height: 101px;

							&:before{
								background-image: url(../images/home/icon-charity-w.png);
								background-repeat: no-repeat;
							}
						}
					}
				}

				&:nth-of-type(3){
					a{
						.icon{
							width: 132px;
							height: 101px;

							&:before{
								background-image: url(../images/home/icon-bp-w.png);
								background-repeat: no-repeat;
							}
						}
					}
				}
				*/ }
        #sect3 ul li:not(:last-of-type) {
          margin-right: 1px; }
        #sect3 ul li a {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 265px;
          position: relative;
          background-color: #f2f2f2;
          z-index: 1;
          color: #000;
          text-decoration: none;
          overflow: hidden; }
          #sect3 ul li a .icon svg {
            width: 70px;
            height: auto; }
          #sect3 ul li a .txt {
            font-size: 18px;
            line-height: 1; }
          #sect3 ul li a:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #71bd29;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            transition: all .3s;
            z-index: -1; }
          #sect3 ul li a:hover:after {
            top: 0; }
          #sect3 ul li a:hover .icon svg {
            fill: #fff; }
          #sect3 ul li a:hover .txt {
            color: #fff; }
  .fbWrap {
    padding-top: 40px; }
    .fbWrap .in {
      width: 500px;
      margin: 0 auto; }
    .fbWrap .fb_iframe_widget,
    .fbWrap .fb_iframe_widget span,
    .fbWrap .fb_iframe_widget iframe[style] {
      width: 100% !important; } }
