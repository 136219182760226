@charset "UTF-8";

// IMPPRT INIT
@import "lib/_sanitize";    // Reset CSS
@import "_setting";

#screen{
	width: 100%;
	position: relative;
	height: 100vh;

	.topImg{
		width: 100%;
		height: 100vh;

		img{
			min-width: 100%;
			min-height: 100%;
			height: auto;
			width: auto;

			&.bg{
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				transform: translateX(-50%) translateY(-50%);
				transition: all 20s;

				&.CurrentImg{
					max-width: none;
					max-height: none;
					transform: translateX(-50%) translateY(-50%) scale(1.5);
				}
			}
		}
	}
}

#wrap{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	z-index: -1;
}




/* ----------------------- SVG ---------------------- */

#mainV{
	position: relative;
	width: 100%;
	height: 100%;
	
	svg{
		transform: rotate(-90deg);
	}
}

.slick-dotted.slick-slider{
	margin-bottom: 0;
}


.circle{
	fill: transparent;
	stroke: $green;
	stroke-width: 8;
	stroke-linecap: round;
}

.circle2{
	fill: transparent;
	stroke: #fff;
	stroke-width: 2;
	stroke-dasharray: 0 6;
	stroke-linecap: round;
}

#baseLine,
#loader{
	width: 300px;
	height: 300px;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

#loader{
	z-index: 2;
}

#circles{
	width: 300px;
	height: 300px;
	margin: auto auto;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}


/* ------------------- contents ---------------- */
main{
	width: 100%;
	background-color: #fff;
	z-index: 1;
	//margin-top: calc(100vh + 100px);
	margin-top: 100vh;
	padding-top: 60px;
}


#canvasContainer{
	transform: translateY(-100px);
}

#parts{
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;

	#symbol{
		width: 497px;
		height: 497px;

		@if $type == tb or $type == sp{
			max-width: 497px;
			max-height: 497px;
			width: 60%;
			height: calc(100vw * 0.6);

			img{
				width: 100%;
				height: auto;
			}
		}
	}

	#icoScroll{
		width: 25px;
		height: 42px;
		border: 1px solid #fff;
		border-radius: 12px;
		position: absolute;
		bottom: 55px;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
		transition: all .7s;

		span{
			font-size: 11px;
			color: #fff;
			display: block;
			width: 32px;
			height: 22px;
			position: absolute;
			bottom: -24px;
			left: -6px;
			letter-spacing: .2em;
			transition: all .4s;
		}

		&:after{
			content: "";
			display: block;
			width: 4px;
			height: 7px;
		//	border: 2px solid #fff;
			border-radius: 3px;
			position: absolute;
			top: 5px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			animation: kurukuru 2.5s infinite ease-out;

			@keyframes kurukuru{
				80%{
					opacity: 1;
				}
				100%{
					top: 26px;
					opacity: 0;
				}
			}

		}

		&:hover{
			background-color: rgba(#fff, .6);

			span{
				bottom: -34px;
				opacity: 0;
			}
			/*
			&:after{
			//	animation: kurukuru 2s infinite ease-out;
			}

			@keyframes kurukuru{
				50%{
					top: 10px;
				}
			}
			*/
		}
	}
}



#trend{
	@if $type == pc{
		width: 85%;
		margin: 20px auto 0;
	}
	@if $type == tb{
		width: 90%;
		margin: 10px auto 0;
	}
	@if $type == sp{
		width: 90%;
		margin: 10px auto 0;
	}

	h2{
		font-size: 36px;
		line-height: 1;
		text-align: center;
		font-weight: 400;
	}


	#trendBanner{
		@if $type == pc{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 10px;
		}
		@if $type == tb{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 30px;
		}
		@if $type == sp{
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.trendPhoto{
			@if $type == pc{
				width: calc(50% - 10px);
				margin-top: 10px;
			}
			@if $type == tb{
				width: calc(50% - 10px);
				margin-top: 20px;
			}
			@if $type == sp{
				margin-top: 10px;
				width: 100%;

				&#tPhoto2, &#tPhoto3{
					width: calc(50% - 10px);
				}

				&#tPhoto5{
					.tImg{
						width: calc(50% - 10px);
					}
				}
			}

			.tImg{
				position: relative;
				line-height: 1;

				a{
					display: block;
					line-height: 1;
					position: relative;
					background-color: $green;
					text-decoration: none;
					overflow: hidden;

					img{
						width: 100%;
						height: auto;
					}

					.tag{
						font-size: 18px;
						line-height: 1;
						z-index: 2;
						color: #fff;
						height: 18px;
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all .3s;

						@if $type == pc{
						}
						@if $type == tb and $type == sp{
							.hovItem{
								display: none;
							}
						}
					}

					.hovItem{
						width: 100%;
						height: 100%;
						background-color: $green;
						position: absolute;
						top: 100%;
						left: 0;
						color: #fff;
						padding: 20px 30px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						transition: all .3s;

						.title{
							font-size: 16px;
							font-weight: 700;
							width: 100%;
							font-family: $ff_ja;
						}

						p{
							font-size: 13px;
							line-height: 1.6;
							margin-top: 1em;
							width: 100%;
							font-family: $ff_ja;
						}

						.more{
							width: 26px;
							margin: 0 0 0 auto;
							text-align: center;
							border-bottom: 1px solid #fff;
							font-size: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
							line-height: 1;
							position: absolute;
							bottom: 10px;
							right: 10px;
							padding-bottom: 2px;
						}
					}

					&:after{
					}

					&:hover{

						.tag{
							top: -100%;
						}
						.hovItem{
							top: 0;
						}
					}
				}
			}

			&#tPhoto1,
			&#tPhoto4{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.tImg{
					width: calc(50% - 10px);
					height: calc(50% - 5px);
					margin-top: 10px;
				}
			}

			&#tPhoto2,
			&#tPhoto3{
				padding-top: 10px;
			}

			&#tPhoto5{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;

				.tImg{
					width: calc(25% - 14px);
					margin-top: 10px;

					@if $type == sp{
						width: calc(50% - 10px);
					}
				}
			}

			.img11, img13, #tPhoto3{transition-delay: .6s}
			.img12, img14, .img41, .img43{transition-delay: .3s}
		}
	}
}



#sect2{
	width: 100%;
	margin-top: 130px;

	@if  $type == tb or $type == sp{
		margin-top: 50px;
	}

	#sect2Inner{
		display: flex;
		flex-wrap: wrap;

		#item1, #item3, #item4, #item5{
			width: calc(100% / 3);

			a{
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				transition: all .4s;
				background-color: rgba(#000, 0);
				overflow: hidden;
				text-decoration: none;

				&:after, &:before{
					content: "";
					display: block;
					width: 100%;
					height: 0;
					position: absolute;
					background-color: rgba($green, .6);
					transition: all .3s;
					left: 0;
				}

				&:before{
					top: 0;
				}

				&:after{
					bottom: 0;
				}

				.img, .txt{
					position: absolute;
					font-weight: 400;
					transition: all .3s;
					color: #fff;

					span{
						font-size: 36px;
						line-height: 1;
						display: block;

						&:nth-of-type(2){
							font-size: 18px;
							margin-top: 15px;
						}
					}

					@if $type == sp{

						span{
							font-size: 20px;
							line-height: 1;
							display: block;

							&:nth-of-type(2){
								font-size: 12px;
								margin-top: 8px;
							}
						}
					}
				}

				&:hover{
					background-color: rgba(#000, .6);

					&:after, &:before{
						height: 30%;
					}

					.img, .txt{
						top: 50%;
						left: 0;
						width: 100%;
						padding-left: 20px;
						transform: translateY(-50%);

						&:after{
							content: "";
							display: block;
							width: 20px;
							height: 20px;
							border-top: 3px solid $green;
							border-right: 3px solid $green;
							transform: rotate(45deg);
							position: absolute;
							top: 40%;
							right: 35px;
							animation: arrAni 1.5s infinite;
						}

						@keyframes arrAni{
							20%{
								right: 20px;
							}
						}
					}
				}
			}
		}

		#item2{
			width: calc((100% / 3) * 2);
		}

		#item1, #item2{
			height: 619px;
		}

		#item3, #item4, #item5{
			height: 749px;
		}

		.sect2Item{
			background-size: cover;
			background-position: 50% 50%;
		}

		#item1{
		//	background-image: url(../images/sect2-img1.jpg);
			transition-delay: .4s;

			h2.txt{
				position: absolute;
				top: 10%;
				left: calc(100% - 140px);
				color: #fff;
				font-size: 16px;
				height: 38px;
				display: flex;
				align-items: center;
				line-height: 1;

				span{
					font-size: 16px;

					&:nth-of-type(2){
						margin-left: 10px;
						margin-top: 0;
					}
				}
			}
		}

		#item2{
			background-image: url(../images/sect2-img2.png);
			background-position: right bottom;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			#item2Inner{
				width: 80%;

				.title{
					font-size: 36px;
					line-height: 1;
					color: #fff;
					font-weight: 400;
				}

				#newsList{
					list-style: none;
					margin-top: 50px;

					li{
						display: flex;

						.tag{
							font-size: 16px;
							line-height: 1;
							color: #fff;
							font-weight: 700;
							color: #fff;
							width: 75px;
							margin-top: -10px;
						}

						a{
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex: 1;
							border-bottom: 1px solid #fff;
							color: #fff;
							text-decoration: none;
							padding: 15px 0;
							position: relative;
							z-index: 1;
							overflow: hidden;

							&:after{
								content: "";
								display: block;
								width: 120%;
								height: 180%;
								background-color: rgba($green, .6);
								transform: rotate(3deg);
								position: absolute;
								top: 145%;
								left: -5%;
								z-index: -1;
								transition: all .7s;
							}

							&:hover{

								&:after{
									top: -35%
								};
							}

							span{
								position: relative;
								z-index: 1;
								display: block;

								&.newsTitle{
									font-size: 15px;
									padding-left: 5px;
								}

								&.date{
									padding-right: 5px;
									font-size: 30px;
									font-weight: 700;
								}
							}
						}

						&:nth-of-type(1){
							a{
								border-top: 1px solid #fff;
							}
						}
					}
				}

				.more{
					width: 15px;
					height: 108px;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
					overflow: hidden;

					a{
						display: block;
						width: 15px;
						height: 108px;
						position: relative;
						overflow: hidden;

						img{
							display: block;
							position: absolute;
							top: 0;
							left: -15px;
							transition: all .4s;
						}

						&:hover{

							img{
								left: 0;
							}
						}
					}
				}
			}
		}

		#item3{
			background-image: url(../images/sect2-img3.jpg);
			transition-delay: .6s;

			.img{
				top: 20%;
				left: 10%;
				color: #000;
			}
		}

		#item4{
			background-image: url(../images/sect2-img4.jpg);
			transition-delay: .3s;

			a:hover{
				background-color: rgba(#fff, .6);
			}

			h2.img{
				top: 45%;
				left: 10%;
				color: #000;
			}
		}

		#item5{
			background-image: url(../images/sect2-img5.jpg);

			.img{
				top: 60%;
				left: 10%;
			}
		}

		#item1{
			@if $type == tb or $type == sp{
				order: 1;
				width: 50%;
				height: calc(100vw / 2);
			}

			@if $type == sp{
				a{
					h2.txt{
						left: 20%;
						top: 5%;

						span{
							font-size: 13px;

							&:nth-of-type(2){
								width: 25px;
								height: 25px;

								img{
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
			}
		}
		#item2{
			@if  $type == tb or $type == sp{
				order: 0;
				width: 100%;
				height: auto;
				padding: 30px 0;
			}
			@if $type == sp{
				#item2Inner{

					ul#newsList{
						margin-top: 15px;

						li{
							display: block;
							margin-top: 15px;

							&:nth-of-type(1){
								a{
									border-top: 0px solid transparent;
								}
							}

							.tag{
								margin: 0;
							}

							a{
								display: block;
								padding: 5px 0;

								.newsTitle{
									font-size: 14px;
								}

								.date{
									font-size: 16px;
									display: block;
									text-align: right;
								}
							}
						}
					}
				}
			}
		}
		#item3{
			@if  $type == tb or $type == sp{
				order: 2;
				width: 50%;
				height: calc(100vw / 2);
			}
		}
		#item4{
			@if  $type == tb or $type == sp{
				order: 3;
				width: 50%;
				height: calc(100vw / 2);
			}
		}
		#item5{
			@if  $type == tb or $type == sp{
				order: 4;
				width: 50%;
				height: calc(100vw / 2);
			}
		}
	}
}

#sect3{
	margin-top: 100px;

	@if $type == tb or $type == sp{
		margin-top: 40px;
	}

	ul{
		max-width: 1200px;
		width: 90%;
		margin: 0 auto;
		display: flex;
		list-style: none;

		li{
			width: calc(100% / 3);

			&:not(:last-of-type){
				margin-right: 1px;
			}

			@if $type == pc{
				overflow: hidden;
	/*
				&:nth-of-type(1){
					a{
						.icon{
							width: 78px;
							height: 105px;

							&:before{
								background-image: url(../images/home/icon-company-w.png);
								background-repeat: no-repeat;

							}
						}
					}
				}

				&:nth-of-type(2){
					a{
						.icon{
							width: 71px;
							height: 101px;

							&:before{
								background-image: url(../images/home/icon-charity-w.png);
								background-repeat: no-repeat;
							}
						}
					}
				}

				&:nth-of-type(3){
					a{
						.icon{
							width: 132px;
							height: 101px;

							&:before{
								background-image: url(../images/home/icon-bp-w.png);
								background-repeat: no-repeat;
							}
						}
					}
				}
				*/
			}
	
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				width: 100%;
				height: 265px;
				position: relative;
				background-color: #f2f2f2;
				z-index: 1;
				color: #000;
				text-decoration: none;

				@if $type == tb or $type == sp{
					height: 150px;

					.icon{
						width: 45%;
						
						svg{
							width: 100%;
							height: auto;
						}
					}

					.txt{
						font-size: 16px;
						line-height: 1;
					}
				}

				@if $type == pc{
					overflow: hidden;

					.icon{
						svg{
							width: 70px;
							height: auto;
						}
					}
					
					.txt{
						font-size: 18px;
						line-height: 1;
					}

					&:after{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						background-color: $green;
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 1;
						transition: all .3s;
						z-index: -1;
					}

					&:hover{
						&:after{
							top: 0;
						}

						.icon{

							svg{
								fill: #fff;
							}
						}
						
						.txt{
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.fbWrap{
	@if $type == sp{
		padding: 30px 20px 0;
	}
	@if $type == pc{
		padding-top: 40px;
	}
	.in{
		@if $type == sp{
		}
		@if $type == pc{
			width: 500px;
			margin: 0 auto;
		}
	}
	.fb_iframe_widget,
	.fb_iframe_widget span,
	.fb_iframe_widget iframe[style]{
	    width: 100% !important;
	}
}

