/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html{
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

article, aside, footer, nav, section, figcaption, figure, main{
	display: block;
}

sub, sup{
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub{
	bottom: -.25em;
}

sup{
	top: -.5em;
}

img{
	border-style: none;
	vertical-align: top;
}

table{
	border-collapse: collapse;
}

button, input, select, textarea{
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button, html [type="button"], [type="reset"], [type="submit"]{
	-webkit-appearance: button;
}

textarea{
	overflow: auto;
	resize: vertical;
}

[type="checkbox"], [type="radio"]{
	box-sizing: border-box;
	padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex]{
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}
