/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:650px;
$breakpoint_tb		:800px;
$breakpoint_pc		:1000px;


/* Color */
$c_base : #000;/*サイトカラー*/
$c_text : #000; /*テキストに使うフォントカラー*/
$c_href : #000; /*テキスト内で使うリンクカラー*/
$green : #71bd29;

$ff_ja: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; /*日本語用（）*/
$ff_eng: 'Oswald', 'sans-serif', "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'meiryo'; /*英語用*/
$ff_sten: 'Allerta Stencil', sans-serif;
